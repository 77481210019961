<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
      <CTab active>
        <template slot="title">
          <CIcon name="cil-task"/>&nbsp;Filtre
        </template>
        <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
          <CRow>
              <CCol col="3" sm="2">
                <CButton type="submit" size="sm" color="primary" @click="filter">
                  <CIcon name="cil-check-circle"/>
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton type="reset" size="sm" color="danger" @click="clearFilters">
                  <CIcon name="cil-ban"/>
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <hr/>
            <CRow>
              <CCol>
                <CInput
                  label="Fatura Tarihi"
                  horizontal
                  type="date"
                  style="width: 50%"
                  v-model="data.startTime"
                />
                <CInput
                  label=" "
                  horizontal
                  style="width: 50%"
                  type="date"
                  v-model="data.endTime"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Sipariş ID"
                  placeholder="Sipariş ID"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.productionOrderId"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="Sipariş No"
                  placeholder="Sipariş numarası"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.productionOrder"
                />
              </CCol>  
            </CRow>
    
            <CRow>
              <CCol sm="6">
                <CInput
                  label="Fatura No"
                  placeholder="Fatura numarası"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.invoiceNo"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  label="İrsaliye No"
                  placeholder="İrsaliye numarası"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.dispatchNo"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <CInput
                  label="Üretici"
                  placeholder="Üretici"
                  horizontal
                  type="text"
                  v-on:keyup.enter="filter"
                  v-model="data.productionCompany"
                />
              </CCol>
            </CRow>
        </CForm>
      </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "InvoiceFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
              }
       },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter()
            }
       }
   }
</script>